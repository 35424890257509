.rules__wrapper {
  min-height: calc(100vh - 100px);

  padding: 20px 25px;
  color: white;
  background: var(--black);
}

.rules__body,
.rules__heading {
  width: 90%;
  max-width: 1000px;
  margin: auto;

  h1,
  h4,
  p {
    margin-bottom: 20px;
    text-align: justify;
  }

  a {
    color: var(--yellow);

    &:hover {
      color: var(--yellowHover);
    }
  }

  h4 {
    font-size: 1.2rem;
    margin-top: 20px;
  }

  li {
    font-family: montserrat;
    margin-bottom: 10px;
    list-style: square outside;
    margin-left: 25px;
  }
}
