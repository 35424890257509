.requestPage {
	background: var(--black);
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.requestPage__logo {
	width: 100px;
}

.requestPage__title {
	font-size: 2rem;
	color: white;
	margin-bottom: 10px;
	margin-top: 10px;
}
.requestPage__subTitle {
	color: white;
	font-size: 0.8rem;
	margin-bottom: 20px;
}
.requestPage__container {
	width: 400px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	max-width: 90%;
	margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
}
