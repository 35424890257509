.faq__wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 120px;
  width: 95%;
  max-width: 900px;
  margin: 0 auto;
  height: 93vh;
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  border-bottom: 1px solid #373737 !important;
}
