.loginPage {
  background: var(--black);
  height: 93vh;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  align-items: center;
}

.loginPage__logo {
  width: 100px;
}

.loginPage__title {
  font-size: 2rem;
  color: white;
  margin-bottom: 20px;
}

.loginPage__container {
  width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;

  a {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 1000px) {
}
