.terms__wrapper {
  min-height: 100vh;
  width: 100%;
  background: var(--black);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 120px 0;
  color: white;
}

.terms__content {
  width: 1000px;
  max-width: 95%;
}
