.calendar__bookingPopup {
	position: absolute;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.911);
	color: var(--yellow);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.calendar__bookingPopup.disabled {
	display: none;
}

.calendar__bookingPopupWrapper {
	width: 500px;
	max-width: 95%;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.calendar__bookingPopupBody {
	background: var(--yellow);
	color: var(--black);
	padding: 10px;

	h3 {
		margin-bottom: 10px;
	}

	h5 {
		span {
			text-decoration: underline;
		}
	}

	.row {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		p {
			margin-left: 5px;
			font-size: 0.9rem;
			a {
				color: black;

				&:hover {
					text-decoration: underline;
				}
			}
		}
		svg {
			font-size: 1.2rem;
		}
	}
}
.bookingPopupButtonContainer {
	display: flex;
	margin-top: 20px;
}

.bookingPopupButton {
	background: var(--black);
	margin: 5px;
	color: var(--yellow);
	text-align: center;
	padding: 5px;
	width: 100%;
	text-transform: uppercase;

	&:hover {
		background: var(--yellowHover);
		cursor: pointer;
		color: var(--black);
	}
}

.calendar__bookingPopupClose {
	background: var(--black);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;

	&:hover {
		background: var(--yellowHover);
		color: var(--black);
		cursor: pointer;
	}
}

.calendar__bookingPopupCancel {
	background: var(--black);
	border-bottom: 1px solid #131313;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	text-transform: uppercase;

	&:hover {
		background: var(--yellowHover);
		color: var(--black);
		cursor: pointer;
	}
}
