.confirm__wrapper {
  min-height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--black);
}

.confirm__details {
  width: 50vw;
  max-width: 600px;
  h1 {
    color: White;
    text-align: center;
    margin-bottom: 30px;
  }

  div {
    text-align: center;
    padding: 15px;

    margin-bottom: 10px;
  }
}

.confirm__location,
.confirm__date,
.confirm__studio,
.confirm__time,
.confirm__studioCapacity {
  border: 1px solid var(--yellow);
  color: var(--yellow);
  text-transform: uppercase;
}

.confirm__bookButton,
.confirm__accountButton {
  background: var(--yellow);
  margin-top: 30px;
  color: var(--black);
  &:hover {
    background: var(--blue);
    color: var(--yellow);
    cursor: pointer;
  }
}

.confirm__backButton {
  border: var(--yellow) 1px solid;
  color: var(--yellow);
  text-transform: uppercase;

  &:hover {
    background: var(--yellow);
    color: var(--black);
    cursor: pointer;
  }
}

.confirm__error {
  color: red;
  border: red 1px solid;
  font-size: 0.7rem;
}

@media only screen and (max-width: 1000px) {
  .confirm__details {
    width: 95vw;
  }
}
