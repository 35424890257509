.hyveButton {
	cursor: pointer;
	text-transform: uppercase;
	border-radius: none;
	padding: 10px 10px;
	width: 100%;
	background: none;
	border: 2px solid var(--yellow);
	box-shadow: none;
	font-size: 1rem;
	text-align: center;
	text-transform: uppercase;
	height: 60px;
	color:var(--black) &:hover {
		cursor: pointer;
		background: var(--yellow);
		border: var(--blue);
		cursor: pointer;
	}
	&:hover {
		background: var(--yellow);
		color: var(--black);
	}

	&.fill {
		background: var(--yellow);
		color: var(--black);

		&:hover {
			background: var(--blue);
			color: var(--yellow);
			border: 2px solid var(--blue);
		}
	}

	&:disabled {
		background: rgb(192, 192, 192);
		color: rgb(143, 143, 143);
		border: white;

		&:hover {
			cursor: default;
			background: rgb(192, 192, 192);
			color: rgb(143, 143, 143);
		}
	}
}
