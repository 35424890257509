$black: #000;
$semi-black: #d3b908;
$white: #fff;
$dress: #ededed;
$dress-dark: darken($dress, 10%);
$sword: #e6e6e6;
$staff: #bf5507;
$skin: #ffd8ad;
$hair: #c2beb5;
$hair-dark: darken($hair, 10%);
$lips: pink;
$fire: black, #d3b908, #de8531, #efac41;
$fire-reverse: #efac41, #de8531, #d3b908, black;

@keyframes floating {
  to {
    top: 1.5rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: $black;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 1.3rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  h1 {
    font-size: 2.5rem;
  }
}

.message {
  max-width: 700px;
  margin: 5rem auto 0 auto;
}

.gandalf {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  top: 1rem;
  animation: floating 1s infinite alternate ease-in-out;
  div {
    position: absolute;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 400px;
    background-color: $semi-black;
    border-radius: 50%;
  }
  .fireball {
    bottom: -10px;
    left: 50px;
    width: 300px;
    height: 100px;
    border-radius: 50%;
    background: radial-gradient($fire-reverse);
    border: 5px solid $black;
  }
  .skirt {
    bottom: 50px;
    left: 100px;
    border-bottom: 230px solid $dress;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    filter: drop-shadow(0 0 6px $dress-dark);

    &::before {
      content: '';
      position: absolute;
      background-color: $dress;
      width: 100px;
      height: 21px;
      top: 230px;
      left: 0px;
      border-bottom-right-radius: 180%;
      border-bottom-left-radius: 100%;
    }
    &::after {
      content: '';
      position: absolute;
      background-color: $dress;
      width: 100px;
      height: 28px;
      top: 230px;
      left: -100px;
      border-bottom-right-radius: 80%;
      border-bottom-left-radius: 180%;
    }
  }
  .sleeves {
    &::before,
    &::after {
      // sleeves
      content: '';
      position: absolute;
      border-bottom: 70px solid $dress;
      filter: drop-shadow(0 0 6px $dress-dark);
    }
    &::before {
      // sleeve left
      top: 130px;
      left: 191px;
      border-left: 100px solid transparent;
      border-right: 40px solid transparent;
      transform: rotate(-34deg);
    }
    &::after {
      // sleeve right
      top: 127px;
      left: 70px;
      border-left: 40px solid transparent;
      border-right: 100px solid transparent;
      transform: rotate(41deg);
    }
  }
  .shoulders {
    background-color: $dress;
    border-radius: 50%;
    width: 100px;
    height: 130px;
    left: 150px;
    top: 120px;
    .hand {
      width: 33px;
      height: 26px;
      border-radius: 50%;
      background-color: $skin;
      top: -6px;
    }
    .left {
      left: -70px;
      transform: rotate(-20deg);
      &::after {
        // sword
        content: '';
        position: absolute;
        background-color: #e6e6e6;
        width: 126px;
        height: 8px;
        border-radius: 4px;
        transform: rotate(-105deg);
        transform-origin: bottom;
        top: -48px;
        left: -56px;
      }
    }
    .right {
      right: -70px;
      transform: rotate(20deg);
      &::after {
        // staff
        content: '';
        position: absolute;
        background-color: $staff;
        width: 250px;
        height: 5px;
        border-radius: 2.5px;
        transform: rotate(-78deg);
        transform-origin: left;
        bottom: -100px;
        left: 0;
      }
    }
  }
  .head {
    width: 80px;
    height: 90px;
    top: 80px;
    left: 160px;
    background-color: #ffd8ad;
    border-radius: 50%;

    &::before,
    &::after {
      // eyes
      content: '';
      position: absolute;
      background-color: $black;
    }

    &::before {
      width: 13px;
      height: 5px;
      border-radius: 3px;
      top: 42px;
      left: 22px;
      transform: rotate(19deg);
    }
    &::after {
      width: 13px;
      height: 5px;
      border-radius: 3px;
      top: 42px;
      right: 22px;
      transform: rotate(-19deg);
    }

    .hair {
      width: 70px;
      height: 30px;
      background-color: $hair;
      border-radius: 50%;
      top: 0px;
      left: 5px;

      &::before,
      &::after {
        // hair sides
        content: '';
        position: absolute;
        background-color: $hair;
        filter: drop-shadow(2px 5px 0 $hair-dark);
      }

      &::before {
        // hair left
        top: 13px;
        left: -16px;
        width: 25px;
        height: 100px;
        border-top-left-radius: 34px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 20px;
        transform: rotate(8deg);
      }
      &::after {
        // hair right
        top: 13px;
        right: -16px;
        width: 25px;
        height: 100px;
        border-top-left-radius: 15px;
        border-top-right-radius: 34px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 100px;
        transform: rotate(-10deg);
      }
    }
    .beard {
      top: 64px;
      left: 5px;
      border-top: 80px solid $hair;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
      border-radius: 30px;
      filter: drop-shadow(2px 5px 0 $hair-dark);

      &::before {
        // mouth
        content: '';
        position: absolute;
        background-color: $lips;
        width: 20px;
        height: 5px;
        border-radius: 40%;
        top: -70px;
        left: -9px;
      }
    }
  }
}
