* {
  font-family: 'Play', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: baseline;
  --yellow: #e42522;
  --yellowHover: #f13e3b;
  --black: rgb(0, 0, 0);
  --offBlack: #2d2d2d;
  --blue: #222e50;
}

html,
body,
.App,
#root {
  background: rgb(0, 0, 0);
}

html {
  scroll-behavior: smooth;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

h2 {
  font-size: 6rem;
  margin: 0;
  line-height: 0.9;
}

.highlight {
  color: var(--yellow);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}

a {
  color: white;
  text-decoration: none;
}

ul,
ul > li {
  list-style: none;
}

.datetime-input-edit-wrapper {
  font-size: 0.8rem !important;
}
