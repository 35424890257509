.registerPage {
  background: var(--black);
  min-height: 93vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;

  h1 {
    margin-bottom: 20px;
  }
}

.registerPage__container {
  width: 90%;
  max-width: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin: 0px auto;
  margin-bottom: 50px;
}

.registerPage__logo {
  width: 100px;
}
