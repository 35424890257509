.hyveInput {
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;
	width: 100%;
	height: 60px;
	border-radius: 0;

	.hyveInputField {
		font-size: 1rem;
		appearance: none;
		width: 100%;
		height: 100%;
		border: 1px solid rgba(161, 161, 161, 0.288);
		box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px 0px;
		padding: 12px 12px 12px 12px;
		background: none;
		position: relative;
		top: 0;
		left: 0;
		z-index: 1;
		padding: 8px 12px;
		outline: 0;
		border-radius: 0;

		&:disabled {
			background: rgba(128, 128, 128, 0.336);
		}

		&:valid {
			// Hides the label
			background: white;
		}

		&:focus {
			border-color: var(--yellow);
			padding: 4px 6px 20px 6px;
		}

		&:focus + label {
			background: var(--yellow);
			top: 100%;
			margin-top: -20px;
			color: var(--black);
			font-size: 60%;
			padding: 0px 6px;
			z-index: 2;
			text-transform: uppercase;
		}

		&.dark {
			border: 1px solid rgba(236, 236, 236, 0.822);
			color: white;

			&:valid {
				// Hides the label
				background: var(--black);
			}
			&:focus {
				border-color: var(--yellow);
				padding: 4px 6px 20px 6px;
			}
			&:focus + label {
				background: var(--yellow);
				top: 100%;
				margin-top: -20px;
				color: var(--black);
				font-size: 60%;
				padding: 0px 6px;
				z-index: 2;
				text-transform: uppercase;
			}
		}
	}

	label {
		transition: // not padding
			background 0.5s, color 0.2s, top 0.5s, bottom 0.5s, right 0.2s, left 0.2s;
		position: absolute;
		font-size: 0.7rem;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		color: rgba(32, 32, 32, 0.822);
		padding: 7px 6px;
		text-transform: uppercase;
		text-align: left;

		&.dark {
			color: rgba(236, 236, 236, 0.822);
		}
	}
}
